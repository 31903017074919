<template>
  <v-form
    class="mt-2 w-full"
    v-model="formValid"
    ref="enqForm"
    lazy-validation
    @submit.prevent="onSubmitEnquiry"
  >
    <div class="text-3xl text-gray-800 font-bold leading-none py-3">
      Create Your Awesome Page</div>

    <div v-for="(field) in newSppFields" :key="field.fieldName" class="pt-0 pb-0">
      <v-text-field
        :autofocus="field.autofocus"
        :required="field.required"
        :rules="field.validationRules"
        name
        :label="field.label"
        v-model="newSppParams[field.fieldName]"
      ></v-text-field>
    </div>
    <div>
      <v-text-field
        autofocus
        required
        :rules="[v => !!v || 'Page title is required',v => v.length > 6 || 'Page title too short']"
        label="Page Title"
        v-model="newSppParams['page_title']"
      ></v-text-field>
      <div v-if="newPageSlug.length > 6">
        Url will be:
      </div>
      <div v-if="newPageSlug.length > 6">
        /p/{{ newPageSlug }}
      </div>
    </div>
    <!-- <v-checkbox
      v-model="newSppParams.alertAgreed"
      :label="$ft('onboarding.forms.receiveSimilarProps')"
      required
    ></v-checkbox>-->
    <p v-if="formErrors.length">
      <template v-for="(error,index) in formErrors">
        <v-alert :key="index" outlined color="error" icon="warning" :value="true">{{error}}</v-alert>
      </template>
    </p>
    <v-flex class="pa-0 mt-5" xs12 sm12>
      <v-btn
        block
        height="54"
        type="submit"
        color="#9acd32"
        dark
      >{{ $ft("onboarding.forms.submit") }}</v-btn>
    </v-flex>
  </v-form>
</template>
<script>
export default {
  components: {},
  props: {
    formErrors: {
      type: Array,
      default: () => []
    }
  },
  beforeDestroy() {
    // this.$store.commit("clearPropertyEnquiryStatus", {})
  },
  data() {
    return {
      checkboxErrors: "",
      // ask_types: ["Ask for more information", "Ask for a visit", "Ask to be called back",
      //   "Ask for more photos"
      // ],
      promptTandC: false,
      tandcAccepted: true,
      successModel: true,
      // above only needed so success alert can be dismissed
      formValid: false,
      newSppFields: [
        // {
        //   labelTextTKey: "onboarding.forms.page_title",
        //   autofocus: true,
        //   fieldName: "page_title",
        //   inputType: "text",
        //   // validationRules: [v => !!v || "Message is required"]
        // },
        // {
        //   label: "Your email",
        //   // labelTextTKey: "onboarding.core.email",
        //   fieldName: "email",
        //   inputType: "text",
        //   required: true,
        //   validationRules: [
        //     v => !!v || "E-mail is required",
        //     v =>
        //       /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        //       "E-mail must be valid"
        //   ]
        // }
      ],
      newSppParams: {
        page_title: ""
      }
    }
  },
  computed: {
    newPageSlug() {
      let pageTitle = this.newSppParams.page_title || ""
      return pageTitle.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-")
    }
  },
  watch: {
    tandcAccepted(newValue, oldValue) {
      if (newValue === true) {
        this.promptTandC = false
      }
    }
  },
  methods: {
    onSubmitEnquiry() {
      // if (this.newSppParams.alertAgreed) {
      //   this.newSppParams.message =
      //     "I would like to recieve info about similar properties"
      // }
      this.formValid = this.$refs.enqForm.validate()
      if (!this.formValid) {
        return
      }
      this.newSppParams.master_slug = this.newPageSlug
      this.$emit("runFormSubmit", this.newSppParams)
    }
  }
}
</script>