/* eslint no-console: 0 */
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
import Vue from 'vue'

// import '@jsroot/css/weebrix.css'
import 'vuetify/src/styles/styles.sass'
// It is only when I activate vuetify below that
// stylesheets get injected
// import vuetify from '../plugins/vuetify' // path to vuetify export
import SignUpHoist from '@jsroot/apps/onboarding/hoists/sign-up-hoist.vue'
import CreateSppHoist from '@jsroot/apps/onboarding/hoists/create-spp-hoist.vue'

import FastI18n from '../plugins/FastI18n'
Vue.use(FastI18n)

import Vuetify from 'vuetify/lib'

// Vue.use(Vuetify, {
//   components: { VApp },
// })

Vue.use(Vuetify)
const vuetify = new Vuetify({})
Vue.use(TurbolinksAdapter)


var bus = new Vue()
// https://riptutorial.com/vue-js/example/20841/how-to-deal-with-deprecation-of--dispatch-and--broadcast---bus-event-pattern-
window.boosterBus = bus
// Instead of below could also pass in via data to each
// vue instance but this seems cleaner
Vue.prototype.$eventHub = bus // Global event bus
// https://medium.com/vuejobs/create-a-global-event-bus-in-vue-js-838a5d9ab03a
document.addEventListener('turbolinks:load', () => {
  let infoEl = document.getElementById("info-el")
  if (infoEl) {
    let targetElId = infoEl.getAttribute('data-target-id')
    let compToRender = SignUpHoist
    let mainCompName = infoEl.getAttribute('data-main-comp')
    if (mainCompName === "CreateSppHoist") {
      compToRender = CreateSppHoist
    }
    window.appBtm = new Vue({
      vuetify,
      // Considered passing in bus below but went for prototype instead
      // data: () => {
      //   return {
      //     bus: bus,
      //   }
      // },
      render: h => h(compToRender)
      // Trying below results in the message
      // Either pre-compile the templates into render functions, or use the compiler-included build
      // components: { BoosterPageDetails, BoosterPagesList }
    }).$mount(targetElId)
  }
})
// below for stats:
Vue.config.performance = true
