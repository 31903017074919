<template>
  <v-form
    class="mt-2 w-full"
    v-model="formValid"
    ref="enqForm"
    lazy-validation
    @submit.prevent="onSubmitEnquiry"
  >
    <div class="text-3xl text-gray-800 font-bold leading-none py-3">
      Start your free 14 day trial
    </div>
    <div v-for="(field) in propertyEnquiryShortFields" :key="field.fieldName" class="pt-0 pb-0">
      <v-textarea
        v-if="field.rows"
        :rows="field.rows"
        :name="field.fieldName"
        :type="field.type"
        :autofocus="field.autofocus"
        :label="$ft(field.labelTextTKey)"
        v-model="leaveMessageParams.contact[field.fieldName]"
      ></v-textarea>
      <v-text-field
        v-else
        class="property-contact"
        solo
        :required="field.required"
        :rules="field.validationRules"
        :autofocus="field.autofocus"
        name
        :label="$ft(field.labelTextTKey)"
        v-model="leaveMessageParams.contact[field.fieldName]"
      ></v-text-field>
    </div>
    <!-- <v-checkbox
      v-model="leaveMessageParams.alertAgreed"
      :label="$ft('onboarding.forms.receiveSimilarProps')"
      required
    ></v-checkbox> -->
    <p v-if="formErrors.length">
      <template v-for="(error,index) in formErrors">
        <v-alert :key="index" outlined color="error" icon="warning" :value="true">{{error}}</v-alert>
      </template>
    </p>
    <v-flex class="pa-0" xs12 sm12>
      <v-btn
        block
        height="54"
        type="submit"
        color="#9acd32"
        dark
      >{{ $ft("onboarding.forms.submit") }}</v-btn>
    </v-flex>
  </v-form>
</template>
<script>
export default {
  components: {},
  props: {
    formErrors: {
      type: Array,
      default: () => []
    },
  },
  // beforeDestroy() {
  // },
  data() {
    return {
      checkboxErrors: "",
      // ask_types: ["Ask for more information", "Ask for a visit", "Ask to be called back",
      //   "Ask for more photos"
      // ],
      promptTandC: false,
      tandcAccepted: true,
      successModel: true,
      // above only needed so success alert can be dismissed
      formValid: false,
      propertyEnquiryShortFields: [
        // {
        //   labelTextTKey: "onboarding.core.message",
        //   fieldName: "message",
        //   inputType: "text",
        //   rows: 5,
        //   validationRules: [v => !!v || "Message is required"]
        // },
        {
          labelTextTKey: "onboarding.core.email",
          fieldName: "email",
          inputType: "text",
          required: true,
          autofocus: true,
          validationRules: [
            v => !!v || "E-mail is required",
            v =>
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
              "E-mail must be valid"
          ]
        },
      ],
      leaveMessageParams: {
        contact: {
          pref_lang: "English",
          name: "",
          message: ""
        },
      }
    }
  },
  computed: {
  },
  watch: {
    tandcAccepted(newValue, oldValue) {
      if (newValue === true) {
        this.promptTandC = false
      }
    }
  },
  methods: {
    onSubmitEnquiry() {
      // if (this.leaveMessageParams.alertAgreed) {
      //   this.leaveMessageParams.contact.message =
      //     "I would like to recieve info about similar properties"
      // }
      this.formValid = this.$refs.enqForm.validate()
      if (!this.formValid) {
        return
      }
      this.$emit("runFormSubmit", this.leaveMessageParams)
    }
  }
}
</script>