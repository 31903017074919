<template>
  <div>
    <section class="bg-blue-100 border-b py-8">
      <div class="container mx-auto m-8">
        <div class="flex flex-wrap">
          <div class="w-5/6 lg:w-1/2 py-4 px-3">
            <h3 class="text-4xl text-gray-800 font-bold leading-none mb-3">
              Create Your Outstanding
              <span style="color: #5c6ac4;">page</span> now!
            </h3>
            <!-- <p class="text-gray-600 mb-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at ipsum eu
          nunc commodo posuere et sit amet ligula.<br /><br />
            </p>-->
          </div>
          <div class="w-full lg:w-1/2">
            <v-form
              class="mt-2"
              v-model="formValid"
              ref="signUpForm"
              lazy-validation
              @submit.prevent="onSubmitSignUp"
            >
              <div class="lg:flex">
                <div
                  v-for="(field) in propertyEnquiryShortFields"
                  :key="field.fieldName"
                  class="pt-0 pb-0 lg:w-3/5"
                >
                  <v-text-field
                    :autofocus="field.autofocus"
                    class="property-contact"
                    outlined
                    background-color="white"
                    :required="field.required"
                    :rules="field.validationRules"
                    name
                    placeholder="Enter title for your page"
                    :label="field.labelText"
                    v-model="signUpParams.contact[field.fieldName]"
                  ></v-text-field>
                </div>
                <div
                  class="mx-auto lg:mx-0 hover:underline font-bold rounded-md mt-0 opacity-75 text-white"
                >
                  <v-btn class="gradient" block height="54" type="submit" dark>Create Page</v-btn>
                </div>
              </div>
              <p
                x-show="error"
                x-text="error"
                style="display: none;"
                class="lg:absolute mt-4 text-white font-medium"
              ></p>
            </v-form>
            <p v-if="formErrors.length">
              <template v-for="(error,index) in formErrors">
                <v-alert
                  dark
                  :key="index"
                  outlined
                  color="error"
                  icon="warning"
                  :value="true"
                >{{error}}</v-alert>
              </template>
            </p>
            <p v-if="formSuccess.length">
              <v-alert
                light
                outlined
                color="success"
                dismissible
                v-model="successModel"
              >{{ formSuccess }}</v-alert>
            </p>
            <div>
              <template v-if="formSending">
                <v-progress-linear :indeterminate="true"></v-progress-linear>
              </template>
            </div>

            <p class="text-gray-600 mb-8">
              <!-- Try PropertySquares free for 14 days, no credit card required. -->
              <br />
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
</style>
<script>
// // import StartTrialForm from "@jsroot/apps/onboarding/forms/StartTrialForm"
export default {
  components: {
    // StartTrialForm
  },
  methods: {
    onSubmitSignUp(event) {
      // if (this.moreInfoParams.alertAgreed) {
      //   this.moreInfoParams.contact.message =
      //     "I would like to recieve info about similar properties"
      // }
      this.formValid = this.$refs.signUpForm.validate()
      if (!this.formValid) {
        return
      }
      this.$emit("runCreateSpp", this.signUpParams)
    }
  },
  props: {
    formErrors: {
      type: Array,
      default: () => []
    },
    formSending: {
      type: Boolean,
      default: () => false
    },
    formSuccess: {
      type: String,
      default: () => ""
    }
  },
  // mounted: function() {  },
  data() {
    return {
      successModel: true,
      formValid: false,
      propertyEnquiryShortFields: [
        {
          // labelTextTKey: "onboarding.core.title",
          labelText: "Title",
          fieldName: "title",
          inputType: "text",
          required: true,
          autofocus: this.focusTitle,
          validationRules: [
            v => !!v || "Title is required",
            // v =>
            //   /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            //   "E-mail must be valid"
          ]
        }
      ],
      signUpParams: {
        contact: {
          title: "",
          message: ""
        }
      }
    }
  },
  watch: {},
  computed: {
    focusTitle() {
      // TODO - figure out a way of focusing this when its within scroll view
      return false
    }
    // showModal: {
    //   // getter
    //   get: function() {
    //     return this.showModalProp
    //   },
    //   // setter
    //   set: function(newValue) {
    //     this.$emit("update:showModalProp", newValue)
    //   }
    // }
  }
}
</script>
<style>
</style>