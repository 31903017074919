<template>
  <v-app>
    <SignUpSection
      :formSuccess="formSuccess"
      :formSending="formSending"
      :formErrors="formErrors"
      @runSignUp="runFormSubmit"
    ></SignUpSection>
    <v-container pa-5>
      <v-layout row>
        <v-flex xs12>
          <v-row justify="center">
            <ModalFormContainer
              :formApiUrl="formApiUrl"
              :formName="modalFormName"
              :formSuccess="formSuccess"
              :formSending="formSending"
              :formErrors="formErrors"
              @runFormSubmit="runFormSubmit"
              v-bind:showModalProp.sync="showModalForm"
            ></ModalFormContainer>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import apiService from "@jsroot/services/api-public"
import ModalFormContainer from "@jsroot/apps/onboarding/modal-forms/ModalFormContainer"
import SignUpSection from "@jsroot/apps/onboarding/forms/SignUpSection"
import { formsHelper } from "@jsroot/mixins/forms-helper"
export default {
  mixins: [formsHelper],
  components: {
    SignUpSection,
    ModalFormContainer
  },
  methods: {
    runFormSubmit(moreInfoParams, apiUrl) {
      this.formSending = true
      // let apiUrl = "/api_public/v1/forms/onboarding_leave_message"
      apiService()
        .put(apiUrl, moreInfoParams)
        .then(
          response => {
            this.formSending = false
            this.formSuccess = response.data.success_message
            // this.formSuccess = "Thank you.  We will contact you shortly."
          },
          err => {
            this.formSending = false
            if (err.response.data.error_messages) {
              this.formErrors = [err.response.data.error_messages]
            } else {
              this.formErrors = [err]
            }
            console.log(err)
          }
        )
    },
    startRequestQuote(var0) {
      this.formApiUrl = "/api_public/v1/forms/onboarding_request_quote"
      this.modalFormName = "RequestQuoteForm"
      this.showModalForm = true
    },
    startStartTrial(var0) {
      this.formApiUrl = "/api_public/v1/forms/onboarding_sign_up"
      this.modalFormName = "StartTrialForm"
      this.showModalForm = true
    },
    startLeaveMessage(var0) {
      this.formApiUrl = "/api_public/v1/forms/onboarding_leave_message"
      this.modalFormName = "LeaveMessageForm"
      this.showModalForm = true
    }
  },
  watch: {
    showModalForm: {
      handler(newVal, oldVal) {
        if (!newVal) {
          // clears out modal form when it is closed
          this.formSuccess = ""
        }
      }
    }
  },
  mounted() {
    // if (INMOAPP.bsd && INMOAPP.bsd.booster_page) {
    //   this.boosterPage = INMOAPP.bsd.booster_page
    // }
  },
  created() {
    // this.$parent.$data.bus.$on("start-trial", this.startStartTrial)
    // had I passed the eventbus as data on the root element
    // would have needed above syntax
    this.$eventHub.$on("request-quote", this.startRequestQuote)
    this.$eventHub.$on("start-trial", this.startStartTrial)
    this.$eventHub.$on("leave-message", this.startLeaveMessage)
  },
  beforeDestroy() {
    this.$eventHub.$off("request-quote")
    this.$eventHub.$off("start-trial")
    this.$eventHub.$off("leave-message")
  },
  data: function() {
    return {
      formApiUrl: "",
      modalFormName: "",
      showModalForm: false
      // showAddListingModal: false
    }
  }
}
</script>
<style>
.v-application--wrap {
  min-height: unset !important;
}
</style>
