<template>
  <v-app>
    <!-- inline: -->
    <InlineCreateSppForm
      :formSuccess="formSuccess"
      :formSending="formSending"
      :formErrors="formErrors"
      @runCreateSpp="runCreateSpp"
    ></InlineCreateSppForm>
    <v-container pa-5>
      <v-layout row>
        <v-flex xs12>
          <v-row justify="center">
            <ModalFormContainer
              :formApiUrl="formApiUrl"
              :formName="modalFormName"
              :formSuccess="formSuccess"
              :formSending="formSending"
              :formErrors="formErrors"
              @runFormSubmit="runCreateSpp"
              v-bind:showModalProp.sync="showModalForm"
            ></ModalFormContainer>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>
<script>
import apiService from "@jsroot/services/api-public"
import ModalFormContainer from "@jsroot/apps/onboarding/modal-forms/ModalFormContainer"
import InlineCreateSppForm from "@jsroot/apps/spp/forms/InlineCreateSppForm"
import { formsHelper } from "@jsroot/mixins/forms-helper"
export default {
  mixins: [formsHelper],
  components: {
    InlineCreateSppForm,
    ModalFormContainer
  },
  methods: {
    runCreateSpp(newSppParams) {
      this.formSending = true
      let apiUrl = "/api_public/v1/spp/create_spp"
      apiService()
        .put(apiUrl, newSppParams)
        .then(
          response => {
            this.formSending = false
            // this.formSuccess = response.data.success_message
            window.location.href = response.data.redir_path
            // this.formSuccess = "Thank you.  We will contact you shortly."
          },
          err => {
            this.formSending = false
            if (err.response.data.error_messages) {
              this.formErrors = [err.response.data.error_messages]
            } else {
              this.formErrors = [err]
            }
            console.log(err)
          }
        )
    },
    startCreateSpp(var0) {
      // this.formApiUrl = "/api_public/v1/forms/onboarding_request_quote"
      this.modalFormName = "CreateSppForm"
      this.showModalForm = true
    }
  },
  watch: {
    showModalForm: {
      handler(newVal, oldVal) {
        if (!newVal) {
          // clears out modal form when it is closed
          this.formSuccess = ""
        }
      }
    }
  },
  mounted() {
    // if (INMOAPP.bsd && INMOAPP.bsd.booster_page) {
    //   this.boosterPage = INMOAPP.bsd.booster_page
    // }
  },
  created() {
    // this.$parent.$data.bus.$on("start-trial", this.startStartTrial)
    // had I passed the eventbus as data on the root element
    // would have needed above syntax
    this.$eventHub.$on("create-spp", this.startCreateSpp)
  },
  beforeDestroy() {
    this.$eventHub.$off("create-spp")
  },
  data: function() {
    return {
      formApiUrl: "",
      modalFormName: "",
      showModalForm: false
      // showAddListingModal: false
    }
  }
}
</script>
<style>
.v-application--wrap {
  min-height: unset !important;
}
</style>
