// import _ from 'lodash'
import apiService from "@jsroot/services/api-public"
export const formsHelper = {
  data() {
    return {
      formErrors: [],
      formSending: false,
      formSuccess: "",
      // dirtyFields: {},
      // hasPendingChanges: false,
    }
  },
  mounted() {
    apiService().refreshCsrf()
  },
  methods: {
  }
}