<template>
  <v-dialog max-width="390" v-model="showModal">
    <v-card class="inmo-card px-0 py-0">
      <v-card-text>
        <v-layout v-if="formSuccess.length" wrap row>
          <v-flex text-xs-center xs12 class="mt-5">
            <v-alert
              class="w-full"
              light
              @input="reloadLocation"
              outlined
              color="success"
            >{{ formSuccess }}</v-alert>
          </v-flex>
          <v-flex text-xs-center xs12>
            <v-btn block height="36" type="submit" color="#9acd32" dark @click="closeModal">Ok</v-btn>
          </v-flex>
        </v-layout>
        <v-layout v-else wrap row>
          <v-flex text-xs-center xs12>
            <component :is="formName" :formErrors="formErrors" @runFormSubmit="runFormSubmit"></component>
          </v-flex>
          <v-flex text-xs-center xs12>
            <template v-if="formSending">
              <v-progress-linear :indeterminate="true"></v-progress-linear>
            </template>
          </v-flex>
        </v-layout>
        <!-- <v-layout wrap row>
        </v-layout>-->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style>
</style>
<script>
import CreateSppForm from "@jsroot/apps/spp/forms/CreateSppForm"
import StartTrialForm from "@jsroot/apps/onboarding/forms/StartTrialForm"
import LeaveMessageForm from "@jsroot/apps/onboarding/forms/LeaveMessageForm"
import RequestQuoteForm from "@jsroot/apps/onboarding/forms/RequestQuoteForm"
export default {
  components: {
    CreateSppForm,
    RequestQuoteForm,
    LeaveMessageForm,
    StartTrialForm
  },
  props: {
    formApiUrl: {
      type: String,
      default: "StartTrialForm"
    },
    formName: {
      type: String,
      default: "StartTrialForm"
    },
    showModalProp: {
      type: Boolean
    },
    formErrors: {
      type: Array,
      default: () => []
    },
    formSending: {
      type: Boolean,
      default: () => false
    },
    formSuccess: {
      type: String,
      default: () => ""
    }
  },
  // mounted: function() {  },
  data() {
    return {
      // formValid: false,
      // showModal: false,
      // newPageParams: {},
      // validationRules: [
      //   v => !!v || "Title for page is required"
      // ]
    }
  },
  watch: {},
  computed: {
    showModal: {
      // getter
      get: function() {
        return this.showModalProp
      },
      // setter
      set: function(newValue) {
        this.$emit("update:showModalProp", newValue)
      }
    }
  },
  methods: {
    runFormSubmit(formParams) {
      this.$emit("runFormSubmit", formParams, this.formApiUrl)
    },
    closeModal() {
      // this.showModalProp = false
      this.$emit("update:showModalProp", false)
      // this.$emit("cleanupAddListing")
    },
    reloadLocation() {
      location.reload()
    }
    // modalActivated() {},
    // closeModal() {
    //   this.showModalProp = false
    //   // this.$emit("hideCurrentModal")
    // }
  }
}
</script>
<style>
</style>